/*eslint-disable*/
import http from "../Environment.js";

class CategoryService {
  getAll() {
    return http.get("/category");
  }

  getCategoryImage(id) {
    return http.get(`/category/${id}/img`, { responseType: 'arraybuffer'});
  }

}

export default new CategoryService();