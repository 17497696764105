import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";
import { Link } from "react-router-dom";

const HeaderTop = ({ borderStyle }) => {
  const currency = useSelector((state) => state.currency);
  return (
    <div className={clsx("header-top-wap", borderStyle === "fluid-border" && "border-bottom")}>
      <LanguageCurrencyChanger currency={currency} />
      <div className="header-offer">
      <strong>Email : </strong>
        <Link>
          {/* Free delivery on order over{" "} */}
           contact@thevedicproducts.com
          {/* <span> */}
            {/* {currency.currencySymbol + (200 * currency.currencyRate).toFixed(2)} */}
            {/* ₹200 */}
          {/* </span> */}
        </Link>
      </div>
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
