import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CategoryService from "../service/CategoryService";

export const getCategory = createAsyncThunk(
    "getCategory",
    async () => {
      try {
        const res = await CategoryService.getAll();
        const response = res;
        return response.data;
      } catch (error) {
        console.error("Error fetching all category:", error);
        throw error;
      }
    }
  );

  // export const getCategoryImg = createAsyncThunk(
  //   "getCategoryImg",
  //   async (id) => {
  //     try {
  //       const res = await CategoryService.getCategoryImage(id);
  //       const response = res;
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error fetching all category:", error);
  //       throw error;
  //     }
  //   }
  // );

const categorySlice = createSlice({
    name: 'category',
    initialState: {
        categories: [],
        // categoryImg: [],
        status: 'idle',
        error: null,
    },
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(getCategory.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(getCategory.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.categories = action.payload;
          })
          .addCase(getCategory.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          })

          // .addCase(getCategoryImg.pending, (state) => {
          //   state.status = 'loading';
          // })
          // .addCase(getCategoryImg.fulfilled, (state, action) => {
          //   state.status = 'succeeded';
          //   state.categories = action.payload;
          // })
          // .addCase(getCategoryImg.rejected, (state, action) => {
          //   state.status = 'failed';
          //   state.error = action.error.message;
          // })
    },
});

const { reducer } = categorySlice;
export default reducer;
