
const LanguageCurrencyChanger = () => {
  
  return (
    <div className="language-currency-wrap my-3">
      <div className="same-language-currency">
        <p><strong>Call Us : </strong>97882 12605</p>
      </div>
    </div>
  );
};

export default LanguageCurrencyChanger;
