import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PlaceOrderService from "../service/PlaceOrderService";

export const addOrder = createAsyncThunk(
    "addOrder",
    async (formData) => {
      try {
        const res = await PlaceOrderService.add(formData);
        return res.data;
      } catch (error) {
        console.error("Error fetching PlaceOrder:", error);
        throw error;
      }
    }
  );

  const placeOrderSlice = createSlice({
    name: "orders",
    initialState: {
        order: [],
        status: "idle",
        error: null,
      },
    reducers: {},

    extraReducers: (builder) => {
      builder
      .addCase(addOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.order.push(action.payload);
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    },
  });


const { reducer } = placeOrderSlice;
export default reducer;
