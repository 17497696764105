import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteFromCart } from "../../../store/slices/cart-slice"
import ProductService from "../../../store/service/ProductService";


const MenuCart = () => {
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  const [images, setImages] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      const imagePromises = cartItems.map(async (cartItem) => {
        const imgId = cartItem.productImageResponseList[0].id;
        const res = await ProductService.getProductImg(imgId);
        const blob = new Blob([res.data], { type: "image/*" });
        const dataUrl = URL.createObjectURL(blob);
        return { cartItemId: cartItem.cartItemId, imageUrl: dataUrl };
      });

      const imageResults = await Promise.all(imagePromises);

      const imagesObject = imageResults.reduce((acc, { cartItemId, imageUrl }) => {
        acc[cartItemId] = imageUrl;
        return acc;
      }, {});

      setImages(imagesObject);
    };

    fetchImages();
  }, [cartItems]);

  return (
    <div className="shopping-cart-content">
      {cartItems && cartItems.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems.map((item) => {
              return (
                <li className="single-shopping-cart" key={item.cartItemId}>
                  <div className="shopping-cart-img">
                    <Link to={process.env.PUBLIC_URL + "/product/" + item.id}>
                      <img
                        // alt=""
                        // src={process.env.PUBLIC_URL + item.image[0]}
                        src={images[item.cartItemId]}
                        style={{height:"100px"}}
                        // src={ process.env.PUBLIC_URL + "assets/img/Mylapore/Karuvadam.jpg" }
                        alt={item.productName}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={process.env.PUBLIC_URL + "/product/" + item.id}
                      >
                        {" "}
                        {item.productName}{" "}
                      </Link>
                    </h4>
                    <h6>
                      Qty : {item.quantity}
                    </h6>
                    <span>
                    ₹ {item.sellingRate}
                      {/* {discountedPrice !== null
                        ? currency.currencySymbol + finalDiscountedPrice
                        : currency.currencySymbol + finalProductPrice} */}
                    </span>
                    {/* {item.selectedProductColor &&
                    item.selectedProductSize ? (
                      <div className="cart-item-variation">
                        <span>Color: {item.selectedProductColor}</span>
                        <span>Size: {item.selectedProductSize}</span>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => dispatch(deleteFromCart(item.cartItemId))}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Total :{" "}
              <span className="shop-total">
              ₹ {cartItems.reduce((totalPrice, item) => totalPrice + (item.sellingRate * item.quantity), 0)}
                {/* {currency.currencySymbol + cartTotalPrice.toFixed(2)} */}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              view cart
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

export default MenuCart;
